<template>
  <div class="personal-data-editor-send-data-to-egisz">
    <m-checkbox
      v-tooltip="tooltip"
      :value="sendDataToEgisz"
      :disabled="disabled"
      @input="sendDataToEgisz = $event"
    />

    <input
      class="hidden"
      :name="`${prefix}[send_data_to_egisz]`"
      :value="sendDataToEgisz"
    >
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import MCheckbox from '@/vue_present/_base/inputs/MCheckbox/MCheckbox.vue'

export default defineComponent({
  name: 'PersonalEditorSendDataToEgisz',

  components: { MCheckbox },

  props: {
    defaultSendDataToEgisz: { type: [Boolean, String, Number], default: undefined },
    prefix: { type: String, required: true },
  },

  data () {
    return {
      sendDataToEgisz: !gon.application.deny_send_data_to_egisz,
    }
  },

  computed: {
    disabled () {
      return !Services.security.canManageEgisz
    },

    tooltip () {
      return this.disabled
        ? this.t('insufficient_access_rights')
        : ''
    },
  },

  created () {
    if (this.defaultSendDataToEgisz === undefined) { return }

    this.sendDataToEgisz = Boolean(Number(this.defaultSendDataToEgisz))
  },
})
</script>
