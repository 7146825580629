import ContextMenu from '../base'
import { el, mount } from 'redom'
import {
  COMPLAINTS,
  CONCLUSIONS,
  DESTINATIONS,
  DIAGNOSE,
  EXTENSIBLE_LIST,
  FIX_LIST,
} from '../../../configuration/reducer_types'

import { createHeader, DiagnoseWindow, MenuItems, MenuWindow } from './ui'
import { createVueApp } from '@/vue_components/create_vue_app'
import TextAreaProxyRoot
  from '@/plugins/dynamic_forms/components/context_menu/editor_mode/ui/TextareaProxyRoot/TextAreaProxyRoot'

import {
  createTreeFromArray,
  createTreeFromNestedArray,
} from '@/plugins/dynamic_forms/components/context_menu/editor_mode/ui/TextareaProxyRoot/logic'
import {
  loadModalPositionFromCache,
} from '@/plugins/dynamic_forms/components/context_menu/editor_mode/ui/TextareaProxyRoot/cache'

export default class EditModeContextMenu extends ContextMenu {
  textareaProxy = null

  /***
   * :HTML elemtn
   * define public interface
   * @e - source event
   */

  build (editor, e, target) {
    e.preventDefault()
    //check from current element
    if (this.entity) {
      if (this.entity.isIdent(event.target) && this.openedData === true) {
        return
      }
    }
    super.build(editor, e, target)
  }

  create (e) {
    if ([COMPLAINTS, DESTINATIONS, CONCLUSIONS, EXTENSIBLE_LIST].includes(this.entity.type)) {
      this.__createTextareaProxy(e)

      return false
    }

    this.window = (new MenuWindow()).el
    this._setMenuPositon(e)
    this._attactHeader()
    this._attactBody(e)
    this._adjustContextMenu(this.window)

    return true
  }

  /**
   * redefine parent method
   */
  resolveRemove () {
    const resolve = super.resolveRemove()
    const target = event.target
    if (!resolve || this._isControl(target)) {
      return false
    }

    if (this.entity && this.entity.type === 'ext_list' && Array.prototype.includes.call(target.classList, 'contexted_li')) return false
    if (this.notClose) {
      // check click on entity
      const entity = this.entityManager.getEntity(event.target)
      if (entity !== false && entity.contexted && this.entity) {
        // check this entity
        if (this.entity.isIdent(entity.element) && this.openedData) {
          return false
        }
        this.build(tinymce.activeEditor, event, entity.element)

        return false
      }
      this.openedData = false
      this._clearContent()

      return false
    }

    return true
  }

  _clearContent () {
    this.menuBody.innerHTML = '...'
  }

  /**
   * boolean force - flag force delete contextMenu
   */
  _isControl (item) {
    return item && item.classList && item.classList.contains('control')
  }

  _attactHeader () {
    createHeader(this)
  }

  /**
   * :void
   * @private
   * menu - THML element
   */
  _attactBody (e) {
    let body = false
    this.menuBody = el('div#menu-body-container')
    switch (this.entity.type) {
      case FIX_LIST:
        body = new MenuItems(this)
        break
      case DIAGNOSE:
        body = new DiagnoseWindow(this)
        break
      default:
        throw new Error('undefind entity type')
    }
    mount(this.menuBody, body)
    mount(this.window, this.menuBody)
  }

  _postCreate () {
    this._bindDragAndDrop(this.window)
    if (!this.moutnElement) return
    const contentH = this.windowHeight - this.hiddenPartSize
    this.moutnElement.style.height = contentH + 'px'
    this.moutnElement.style.overflowY = 'scroll'
  }

  __createTextareaProxy (e) {
    const el = '#complaints_destinations_conclusions'

    if (this.textareaProxy) {
      this.textareaProxy.$destroy()
      const element = document.querySelector(el)
      element.parentNode.removeChild(element)
    }

    this.__renderTemplatePopover(el, e)
  }

  __renderTemplatePopover (el, e) {
    const that = this

    const api = async () => {
      if (that.entity.type === EXTENSIBLE_LIST) {
        const value = (that.entity?.data?.value || []).flat()

        return Promise.resolve(createTreeFromArray(value))
      }

      return Promise.resolve(createTreeFromNestedArray(
        await that.container.get('api').getRelationCategories(that.entity.data.categories)
      ))
    }

    const elementOffset = loadModalPositionFromCache(undefined, $(e.target).offset())
    const { innerText } = that.target
    const defaultValue = that.target.innerText[0] === ' '
      ? innerText.slice(1)
      : innerText

    this.textareaProxy = createVueApp({
      el,
      name: 'TextAreaProxyRoot',
      render: (h) => h(TextAreaProxyRoot, {
        props: {
          id: el.slice(1),
          target: that.target,
          elementOffset,
          defaultValue,
          name: that.entity.options.title,
          api,
          actions: that.entity.actions,
        },
      }),
    })
  }
}
