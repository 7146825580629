import { initMultipartSelector } from '@/helpers/initMultipartSelector'
import { createVueApp } from '@/vue_components/create_vue_app'
import PersonalEditorSendDataToEgisz
  from '@/vue_apps/ClientsModule/components/PersonalData/PersonalDataEditor/PersonalDataEditorSendDataToEgisz/PersonalEditorSendDataToEgisz.vue'

const vueApps = {}

export const createPersonalDataEditorSendDataToEgiszApp = (
  parentForm: string,
  isNewMode = false,
  {
    el = '#personal_data_editor_send_data_to_egisz_app_root',
    sendDataToEgiszQuery = '#send_data_to_egisz',
    prefix = 'client',
  } = {}) => {
  const currentApp = `${parentForm} ${el}`

  if (!document.querySelector(currentApp)) { return }

  const defaultSendDataToEgisz = isNewMode
    ? !gon.application.deny_send_data_to_egisz
    : initMultipartSelector(parentForm)<HTMLInputElement>(sendDataToEgiszQuery).checked

  if (vueApps[currentApp]?.$destroy) { vueApps[currentApp].$destroy() }

  vueApps[currentApp] = createVueApp({
    el: currentApp,
    name: `PersonalEditorSendDataToEgiszApp:${parentForm}`,
    render: (h: any) => h(PersonalEditorSendDataToEgisz, {
      props: {
        defaultSendDataToEgisz,
        prefix,
      },
    }),
  })
}
