import {
  CALCULATE,
  COMPLAINTS,
  CONCLUSIONS,
  DESTINATIONS,
  DIAGNOSE,
  ENTRIES_VARIABLE,
  EXTENSIBLE_LIST,
  FIX_LIST,
  VARIABLES,
} from '../configuration/reducer_types'

export const CLEARABLE_ENTITIES = {
  SELECTORS: [
    '.list',
    '.d_entity',
    `.${CALCULATE}`,
    `.${VARIABLES}`,
    `.${DIAGNOSE}`,
    `.${ENTRIES_VARIABLE}`,
  ] as const,

  INDICATIONS: {
    ATTRIBUTES: [
      'data-value',
      'data-title',
      'data-id',
      'data-name',
      'data-type',
      'contenteditable',
    ] as const,

    CLASSES: [
      'mceNonEditable',
      'element',
      'list',
      'd_entity',
      FIX_LIST,
      EXTENSIBLE_LIST,
      ENTRIES_VARIABLE,
      VARIABLES,
      CALCULATE,
      DIAGNOSE,
      COMPLAINTS,
      CONCLUSIONS,
      DESTINATIONS,
    ] as const,
  },
}
