import BaseModal from './base'
import htmlHelper from '../../helpers/htmlHelper'
import PrintAreaManager from '../../entities/print_area_manager'
import BasedOnPreviousModal from '@/vue_components/template_records/based_on_previous_modal.vue'
import pubSub from '@/services/epic_pub_sub.js'
import ModalWindowSelectLegalRepresentative
  from '@/vue_components/modal_windows/legal_representative/ModalWindowSelectLegalRepresentative.vue'
import { SET_EDITOR_OPENED } from '@/plugins/dynamic_forms/reducers/editor_opened'
import { getReduxStore } from '@/specific/entries/utils'
import {
  getPrintingToolsFromConfiguration,
  getPrintingToolsFromItem,
  getPrintingToolsValues,
} from '@/helpers/printing_tools'
import { stringSanitize } from '@/plugins/dynamic_forms/reducers/helpers'
import { cloneDeep } from 'lodash'
import { specSymbolsSanitize } from '@/specific/entries/submit_sanitizer'
import { pubSubPromise } from '@/_medods_standart_library/msl'
import { camelToSnake } from '@/_api/_requests/helpers'
import { createVueApp } from '@/vue_components/create_vue_app'

export default class FrontModal extends BaseModal {
  /**
   * @param {String} html
   * @private
   */
  static _sanitizeHtml (html) {
    return html
      .replace('<p><br data-mce-bogus="1"></p>', '')
  }

  /**
   * @param {*} state - redux state
   * @return {*}
   * @private
   */
  static _sanitizeState (state) {
    const storeState = cloneDeep(state)
    storeState.conclusions = storeState.conclusions.map((conclusion) => ({
      ...conclusion,
      value: stringSanitize(conclusion.value),
    }))

    storeState.complaints = storeState.complaints.map((complaint) => ({
      ...complaint,
      value: stringSanitize(complaint.value),
    }))

    storeState.destinations = storeState.destinations.map((destination) => ({
      ...destination,
      value: stringSanitize(destination.value),
    }))

    return storeState
  }

  constructor (params, builder) {
    super(params, builder)

    this.saveAndCloseButton = this.modalWindow.querySelector('.modal-save-close')
    this.saveAndCloseButton.addEventListener('click', () => {
      this._save({ saveAndClose: true })
    })
  }

  async _setPrintingTools () {
    const protocolPrintingTools = await pubSubPromise('getPrintingToolsValues')
    this.printingToolsValues = getPrintingToolsValues({
      ...camelToSnake(protocolPrintingTools),
      ...getPrintingToolsFromConfiguration(gon.specific),
    })
  }

  async load () {
    console.info('FrontModal::load')

    if (!this.printingToolsValues) {
      await this._setPrintingTools()
    }

    this.editor.loadHtml(this.htmlInput.value)
    this.editor.loadData(this.dataInput.value)
    this.editor.repairVariables()
    $(this.modalBody).loadSpin('stop')
    this.saveButton.innerText = t('complete_edit')
    $(this.modalBody).find('.modal-close').text(t('cancel'))
    this._bindSafeCloseEvents()
    this._bindSafeSaveAsText()

    window.application.form_builder.frontend.globalMap.protocol_changed = false
    console.debug('...load edit mode')

    const store = getReduxStore()
    if (store) {
      store.dispatch({ type: SET_EDITOR_OPENED })
    }

    if (!document.querySelector('#protocol-force-save')) {
      this.forceSaveBtn = $('#frontend-form').find('.modal-footer-right')
        .append(`<button class="btn btn-primary" id="protocol-force-save">${T.save_as_text}</button>`)
      this.forceSaveBtn = $('#protocol-force-save')
      this._bindSafeSaveAsText()
    }

    // БЛ: скрытие кнопки "сохранить как текст" для не СЭМД
    gon.specific.__lastSemdType
      ? this.forceSaveBtn.hide()
      : this.forceSaveBtn.show()

    htmlHelper.restoreHtml()
  }

  __closeTextareaProxy () {
    const { textareaProxy } = window.application.form_builder.current.container.get('contextMenu')
    if (!textareaProxy) { return }
    textareaProxy.$destroy()
  }

  _bindSafeCloseEvents () {
    $(this.modalWindow).find('.modal-close').click((e) => {
      e.stopImmediatePropagation()
      bootbox.confirmYN(T.cancel_template_record_changes, (res) => {
        if (!res) { return }
        $(this.modalWindow).megamodal('hide')
        this.__closeTextareaProxy()
        Services.pubSub.emit('onProtocolClose')
      })
    })
  }

  _bindSafeSaveAsText () {
    $(this.modalWindow).find('#protocol-force-save').click((e) => {
      e.stopImmediatePropagation()
      bootbox.confirmYN(T.save_as_text + '?', (res) => {
        if (!res) return

        this.htmlInput.value = this.editorMountPoint.innerHTML.replace(/class="(.*?)"/g, (match, p1) => {
          if (p1.includes('mce-pagebreak') || p1.includes('print_area')) return match

          return ' '
        })

        this.__closeTextareaProxy()
        $(this.modalWindow).megamodal('hide')
        Services.pubSub.emit('onProtocolForceSave', this.htmlInput.value)
      })
    })
  }

  async _open () {
    super._open()

    console.info('FrontModal:open')
    await this._setPrintingTools()

    this.editor.run(this.printingToolsValues)
    $('.entries_page .btn').css('zIndex', 0)
    $('.btn-sm').css('z-index', '-1')
    $('.nested-diagnosis-checkboxes').css('zIndex', 0)
  }

  _save (params = {}) {
    this.__closeTextareaProxy()

    if (PrintAreaManager.hasIllegalAreas()) {
      Notificator.info(t('wrong_print_area_position'))

      return
    }
    const valEditor = this.editorMountPoint.innerHTML

    if (
      !this._haveLegalRepresentativesVar(valEditor) &&
      !this._haveLegalRepresentativesOrClientVar(valEditor)
    ) {
      this._saveEditProtocol(params)

      return
    }

    this._saveEditProtocol(params)
  }

  _setParamLegalRepresentative (legalId) {
    Services.pubSub.emit('updateLegalRepresentativeId', legalId)
  }

  _haveLegalRepresentativesVar (value) {
    return Utils.includeLegalRepresentativesVar(value)
  }

  _haveLegalRepresentativesOrClientVar (value) {
    return Utils.includeLegalRepresentativesOrClientVar(value)
  }

  _clientIsKid () {
    return moment().diff(gon.specific.client.birthdate, 'years') < 18
  }

  _saveEditProtocol (params = {}) {
    this.entityManager.clearStore()
    this.entityManager.updateEntities()
    this._saveDiagnoses()
    this._saveEntryEntities()

    // Удаление спецсимволов при сохранении (&nbsp; и другие)
    const storeState = FrontModal._sanitizeState(this._store.getState())

    this.htmlInput.value = specSymbolsSanitize(FrontModal._sanitizeHtml(this.editorMountPoint.innerHTML), { noNbsp: true })
    this.dataInput.value = specSymbolsSanitize(JSON.stringify(storeState))
    // super._save()
    this.protocolSaveInput.value = 1
    this.presetHtmlContainer.innerHTML = this.htmlInput.value
    htmlHelper.clearHtml()
    window.application.form_builder.frontend.globalMap.protocol_changed = true
    $('.template-html-container .editor-body').css('border-color', '#8BC34A')

    Services.pubSub.emit('onProtocolEditFinished', {
      printingTools: this.editor.currentPrintingToolsValues,
      templateHtml: this.htmlInput.value,
      templateData: this.dataInput.value,
      protocolId: +document.querySelector('#current_protocol_id')?.value,
      storeState,
      ...params,
    })

    $(this.modalWindow).megamodal('hide')
  }

  _saveEntryEntities () {
    const container = $('.entry-entities')
    const destroyItems = container.find('.entry-entities__item-destroy')
    destroyItems.val(true)
    container.find('.entry-entities__item').hide()
    this.entityManager.entitiesCollection.forEach((item) => {
      const entity = this.entityManager.getEntity(item)
      if (entity?.saveCollbak) { entity.saveCollbak() }
    })
  }

  _saveDiagnoses () {
    const container = $('#nested-diagnoses')
    const destroyItems = container.find('.destroy-item')
    destroyItems.val(1)
    container.find('.nested-diagnosis').hide()
  }

  _bindEvents () {
    this.client = gon.specific.client
    this.clientLegalRepresentatives = gon.specific.client.legal_representatives

    super._bindEvents()
    this.openButton.onclick = this._open.bind(this)
    this.saveButton.onclick = this._save.bind(this)
    if (this.newTemplateButton) {
      this.newTemplateButton.onclick = this._open.bind(this)
    }

    if (this.changeProtocolButton) {
      this.changeProtocolButton.onclick = this._changeProtokolModal.bind(this)
    }

    if (this.basedOnPreviousBtn) {
      this.basedOnPreviousBtn.addEventListener('click', this._openBasedOnPreviousModal)
      this._initBasedOnPreviousModal()
    }

    this._initChooseProtocolModal()
  }

  _initBasedOnPreviousModal () {
    const container = document.createElement('div')
    container.id = 'based_on_previous_modal_container'
    window.document.body.appendChild(container)
    pubSub.subscribe('TEMPLATE_RECORDS.BASED_ON_PREVIOUS_MODAL.ITEM_SELECTED', (entry) => {
      this._changeProtokol(entry)
      this._saveDiagnoses()
    })

    return createVueApp({
      el: '#based_on_previous_modal_container',
      name: 'BasedOnPreviousModalApp',
      render: (h) => h(BasedOnPreviousModal),
      useChildren: true,
    })
  }

  _openBasedOnPreviousModal () {
    pubSub.emitAsync('TEMPLATE_RECORDS.BASED_ON_PREVIOUS_MODAL.SHOW')
  }

  _initChooseProtocolModal () {
    const container = document.createElement('div')
    container.id = 'choose_legal_represintatives_modal'
    window.document.body.appendChild(container)

    return createVueApp({
      el: '#choose_legal_represintatives_modal',
      name: 'ModalWindowSelectLegalRepresentativeApp',
      render: (h) => h(ModalWindowSelectLegalRepresentative, {
        props: {
          legalRepresentatives: this.clientLegalRepresentatives,
        },
      }),
      useChildren: true,
    })
  }

  _changeProtokol (item) {
    this.changedProtocolId = item.id
    this.dataInput.value = item.template_data
    const tmpBlock = document.createElement('tmp')
    tmpBlock.innerHTML = item.template_html
    const storeData = JSON.parse(this.dataInput.value)
    this.entityManager.load(storeData)
    $('#protokol-content').html(tmpBlock.innerHTML)

    /**
     * Вместо использования DOM, используется js
     * в методе _open, в едитор передается this.printingToolsValues
     */
    const gS = gon.specific
    this.printingToolsValues = getPrintingToolsValues({
      ...getPrintingToolsFromConfiguration(gS),
      ...getPrintingToolsFromItem(item),
    })

    this.htmlInput.value = $('#protokol-content').html()
    htmlHelper.clearHtml()
    $(this.protocolSelectModal).megamodal('hide')
    this.protocolSaveInput.value = 0
    this.editor.protocolId = item.id
    this._open.apply(this)
  }

  _changeProtokolModal () {
    $(this.protocolSelectModal).megamodal('show')
    $(this.protocolSelectModal).find('.modal-footer').remove()
    const categoryContainer = $('#category_list')
    categoryContainer.categoriesx({
      url: '/categories/template_records?data=true',
      editable: false,
      groupEdit: false,
      onClick: (item) => {
        if (parseInt(document.querySelector('#entry_is_protokol_save').value) === 1) {
          bootbox.confirm(
            t('rewrite_protocol_content'),
            (e) => {
              if (e) {
                this.lazyLoad(this._changeProtokol.bind(this), item)
              }
            }
          )
        } else {
          this.lazyLoad(this._changeProtokol.bind(this), item)
        }
      },
    })
  }
}
