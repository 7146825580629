import { createVueApp } from '@/vue_components/create_vue_app'
import PersonalDataEditorPositionApp
  from '@/vue_apps/ClientsModule/components/PersonalData/PersonalDataEditor/PersonalDataEditorPositionApp/PersonalDataEditorPositionApp.vue'
import { initMultipartSelector } from '@/helpers/initMultipartSelector'

const vueApps = {}

export const createPersonalDataEditorPositionAppRoot = (
  parentForm: string,
  {
    el = '#personal_data_editor_position_app_root',
    positionQuery = '#client_position',
    nsiProfessionIdQuery = '#client_nsi_profession_id',
    nsiProfessionTitleQuery = '#client_nsi_profession_title',
    prefix = 'client',
  } = {}) => {
  const currentApp = `${parentForm} ${el}`

  if (!document.querySelector(currentApp)) { return }

  const getNode = initMultipartSelector(parentForm)

  if (vueApps[currentApp]?.$destroy) { vueApps[currentApp].$destroy() }

  const props = {
    defaultPosition: getNode<HTMLInputElement>(positionQuery).value,
    defaultNsiProfession: {
      id: getNode<HTMLInputElement>(nsiProfessionIdQuery).value,
      title: getNode<HTMLInputElement>(nsiProfessionTitleQuery).value,
    },
    prefix,
  }

  vueApps[currentApp] = createVueApp({
    el: currentApp,
    name: `PersonalDataEditorPositionApp:${parentForm}`,
    render: (h: any) => h(PersonalDataEditorPositionApp, {
      props,
    }),
  })
}
