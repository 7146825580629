import { CLEARABLE_ENTITIES } from './const'

const htmlHelper = {
  clearHtml (html = false) {
    const editElements = document.querySelectorAll('.t-element')
    if (editElements.length > 0) {
      editElements.forEach((item) => {
        item.classList.remove('t-element')
        item.classList.add('element')
        item.setAttribute('contenteditable', false)
      })
    }
    $('.diagnoses_constructor').hide()
    $('.tree-icon.control').hide()
  },

  restoreHtml () {
    const editElements = document.querySelectorAll('.element')
    if (editElements.length > 0) {
      editElements.forEach((item) => {
        item.classList.remove('element')
        item.classList.add('t-element')
      })
    }
    $('.diagnoses_constructor').show()
    $('.tree-icon.control').show()
  },

  /**
   * Удаление из HTML текста протокола "признаков" переменных, списков и справочников
   * (превращение их в обычные строки)
   * @param {HTMLElement} protocol
   */
  removeEntitiesIndications (protocol) {
    const protocolNode = $(protocol)

    const entitiesListSelectorString = CLEARABLE_ENTITIES.SELECTORS.join(',')
    const spacedAttributesString = CLEARABLE_ENTITIES.INDICATIONS.ATTRIBUTES.join(' ')
    const spacedClassesString = CLEARABLE_ENTITIES.INDICATIONS.CLASSES.join(' ')

    protocolNode
      .find(entitiesListSelectorString)
      .removeAttr(spacedAttributesString)
      .removeClass(spacedClassesString)

    protocolNode
      .find('.diagnoses_constructor')
      .remove()
  },
}

export default htmlHelper
